import React, { Suspense } from "react";
import { Canvas } from "react-three-fiber";
import { OrbitControls, useGLTF } from "@react-three/drei";

import './App.css';

console.log(`i'm eggy bagelface, or bagelface for short, or bagel for shortest
i'm not a good frontend dev, but i'm pretty good at everything else
bagelface@protonmail.com
bagelface.eth
`);

function Model(props) {
  const { scene } = useGLTF("/bagel/scene.gltf");
  return <primitive object={scene} />;
}

function App() {
  return (
    <div className="App">
      <div className="App-header">
        <Canvas pixelRatio={[1, 2]} camera={{ position: [10, 15, 45], fov: 3 }}>
          <ambientLight intensity={1} />
          <Suspense fallback={null}>
            <Model />
          </Suspense>
          <OrbitControls />
        </Canvas>
      </div>
    </div>
  );
}

export default App;
